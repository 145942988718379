import * as React from 'react'
import Layout from '../components/layout'

const DisclaimerPage = () => {
    return (
    <Layout pageTitle="Disclaimer">
        <section className="featured-area">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <div className="section-title text-center">
                            <h3 className="text-uppercase">Website Disclaimer + Privacy</h3>
                        </div>
                    </div>
                </div>
                <strong>Website Disclaimer + Privacy (conform GDPR) PAND99</strong>
                <p>
                    Deze website is eigendom van PARTNERS@WORK V.O.F.
                </p>
                <p>
                    Contactgegevens: PARTNERS@WORK
                </p>
                <p>
                    Adres maatschappelijke zetel: Sint-Gillislaan 99 - 9200 Sint-Gillis
                    Dendermonde
                </p>
                <p>
                    Telefoon: 052 22 29 98
                </p>
                <p>
                    E-mail: info@partnersatwork.be
                </p>
                <p>
                    Ondernemingsnummer: BE 0844.549.306
                </p>
                <p>
                    RPR: Gent, afdeling Dendermonde
                </p>
                <p>
                    Deze Privacy Policy is van toepassing op al onze klanten en leveranciers
                    (huidige, voormalige en toekomstige) en op alle bezoekers van de PAND99
                    website(s).
                </p>
                <p>
                    Door de toegang tot en het gebruik van de website verklaart u zich
                    uitdrukkelijk akkoord met de volgende algemene voorwaarden.
                </p>
                <p>
                    <strong><em>Intellectuele eigendomsrechten</em></strong>
                </p>
                <p>
                    De inhoud van deze site, met inbegrip van de merken, logo's, tekeningen,
                    data, product- of bedrijfsnamen, teksten, beelden e.d. zijn beschermd door
                    intellectuele rechten en behoren toe aan PAND99 of rechthoudende derden.
                    Zij mogen onder geen beding worden gereproduceerd of weergegeven zonder
                    toestemming van PAND99.
                </p>
                <p>
                    De aanwezigheid van andere handelsnamen of merknamen op de website van
                    PAND99 geschiedt na uitdrukkelijke toestemming van de rechthebbenden
                    daarop.
                </p>
                <p>
                    <strong><em>Beperking van aansprakelijkheid</em></strong>
                </p>
                <p>
                    De informatie op de website is van algemene aard. De informatie is niet
                    aangepast aan persoonlijke of specifieke omstandigheden, en kan dus niet
                    als een persoonlijk, professioneel of juridisch advies aan de gebruiker
                    worden beschouwd.
                </p>
                <p>
                    PAND99 levert grote inspanningen opdat de ter beschikking gestelde
                    informatie volledig, juist, nauwkeurig en bijgewerkt zou zijn. Ondanks deze
                    inspanningen kunnen onjuistheden zich voordoen in de ter beschikking
                    gestelde informatie. Indien de verstrekte informatie onjuistheden zou
                    bevatten of indien bepaalde informatie op of via de site onbeschikbaar zou
                    zijn, zal PAND99 de grootst mogelijke inspanning leveren om dit zo snel
                    mogelijk recht te zetten.
                </p>
                <p>
                    PAND99 kan evenwel niet aansprakelijk worden gesteld voor rechtstreekse of
                    onrechtstreekse schade die onstaat uit het gebruik van de informatie op
                    deze site.
                </p>
                <p>
                    Indien u onjuistheden zou vaststellen in de informatie die via de site ter
                    beschikking wordt gesteld, kan u de beheerder van de site contacteren via
                    tom@bitleader.be
                </p>
                <p>
                    De inhoud van de site (links inbegrepen) kan te allen tijde zonder
                    aankondiging of kennisgeving aangepast, gewijzigd of aangevuld worden.
                    PAND99 geeft geen garanties voor de goede werking van de website en kan op
                    geen enkele wijze aansprakelijk gehouden worden voor een slechte werking of
                    tijdelijke (on)beschikbaarheid van de website of voor enige vorm van
                    schade, rechtstreekse of onrechtstreekse, die zou voortvloeien uit de
                    toegang tot of het gebruik van de website.
                </p>
                <p>
                    PAND99 kan in geen geval tegenover wie dan ook, op directe of indirecte,
                    bijzondere of andere wijze aansprakelijk worden gesteld voor schade te
                    wijten aan het gebruik van deze site of van een andere, inzonderheid als
                    gevolg van links of hyperlinks, met inbegrip, zonder beperking, van alle
                    verliezen, werkonderbrekingen, beschadiging van programma's of andere
                    gegevens op het computersysteem, van apparatuur, programmatuur of andere
                    van de gebruiker.
                </p>
                <p>
                    De website kan hyperlinks bevatten naar websites of pagina's van derden, of
                    daar onrechtstreeks naar verwijzen. Het plaatsen van links naar deze
                    websites of pagina's impliceert op geen enkele wijze een impliciete
                    goedkeuring van de inhoud ervan.
                </p>
                <p>
                    PAND99 verklaart uitdrukkelijk dat zij geen zeggenschap heeft over de
                    inhoud of over andere kenmerken van deze websites en kan in geen geval
                    aansprakelijk gehouden worden voor de inhoud of de kenmerken ervan of voor
                    enige andere vorm van schade door het gebruik ervan.
                </p>
                
                <p>
                    <strong><em>Toepasselijk recht en bevoegde rechtbanken.</em></strong>
                </p>
                <p>
                    Het Belgisch recht is van toepassing op deze site. In geval van een geschil
                    zijn enkel de rechtbanken van het arrondissement RPR Gent, afdeling
                    Dendermonde bevoegd.
                </p>
                <p>
                    <strong>Privacybeleid</strong>
                    <br/>
                    <br/>
                </p>
                <p>
                    De Europese Algemene Verordening Gegevensbescherming 2016/679 van 27 april
                    2016 ("Algemene Verordening Gegegevensbescherming"), de wet van 8 december
                    1992 ("Privacywet"), de wet van 13 juni 2005 ("Wet Elektronische
                    Communicatie") en de bijhorende uitvoeringsbesluiten, alsook enige
                    toekomstige wijzigingen hiervan, regelen de bescherming van uw
                    persoonsgegevens.
                </p>
                <p>
                    PAND99 streeft ernaar om haar plichten na te leven en de rechten van de
                    klant te respecteren telkens wanneer PAND99 uw persoonsgegevens verwerkt.
                    Voor meer informatie hieromtrent verwijzen we u graag door naar de website
                    van de Commissie voor de bescherming van de persoonlijke levenssfeer
                    [https://www.privacycommission.be/nl].
                </p>
                <p>
                    PAND99 verwerkt persoonsgegevens overeenkomstig deze privacyverklaring.
                    Voor verdere informatie, vragen of opmerkingen omtrent ons privacy beleid,
                    kunt u terecht bij PARTNERS@WORK VOF, Sint-Gillislaan 99 te 9200
                    Sint-Gillis Dendermonde, en via het e-mailadres info@partnersatwork.be.
                </p>
                <p>
                    E-mailadressen en andere co&#246;rdinaten worden bekomen als u dit
                    expliciet aan ons communiceert (bijvoorbeeld via telefoon, mail, via een
                    invul- of contactformulier, via sociale media).
                </p>
                <p>
                    <strong><em>Verwerkingsdoeleinden</em></strong>
                </p>
                <p>
                    PAND99 verzamelt en verwerkt de persoonsgegevens van klanten en
                    leveranciers voor klanten- en orderbeheer (o.a.
                    klanten/leveranciersadministratie, opvolgen van bestellingen/leveringen,
                    facturatie, opvolgen van de solvabiliteit, het verzenden van
                    dienstverlening) of om op vragen tot informatie te kunnen beantwoorden of
                    desgevallend de gebruiker van uitzonderlijke waarden in kennis te stellen.
                </p>
                <p>
                    PAND99 eerbiedigt de privacy van deze persoonlijke informatie en heeft
                    voldoende veiligheden ingebouwd om deze informatie te beschermen.
                </p>
                <p>
                    <strong><em></em></strong>
                </p>
                <p>
                    <strong>
                        <em>Welke gegevens worden verzameld voor welke doeleinden?</em>
                    </strong>
                </p>
                <p>
                    PAND99 verzamelt enkel persoonsgegevens die haar op vrijwillige basis
                    worden verstrekt.
                </p>
                <p>
                    Je persoonsgegevens kunnen verzameld worden via:
                </p>
                <p>
                    &#216; Contactformulier: door het invullen van de gegevens op het
                    contactformulier, verstrekken de gebruikers toelating aan onze applicatie
                    om deze gegevens te gebruiken om te antwoorden op informatievragen,
                    prijsvragen, boekingen of ieder ander soort vraag.
                </p>
                <p>
                    &#216; Verzamelde gegevens: bedrijfsnaam, voor- en familienaam, e-mail,
                    onderwerp van de aanvraag, BTW nummer of ondernemingsnummer
                </p>
                <p>
                    &#216; Gebruik van onze website, sociale media, cookies, doorklikgedrag,
                    navigatiegegevens,&#8230;
                </p>
                <p>
                    &#216; Gegevens verzameld via mail, telefoon of ander kanaal: deze worden
                    verzameld om de administraite mogelijk te maken van bv. opleidingen, uit te
                    voeren diensten en opdrachten, aankoop, leveren en factureren van bestelde
                    goederen, boekingen, &#8230;
                </p>
                <p>
                    Uw persoonsgegevens worden door PAND99 verwerkt, voor klantenbeheer op
                    basis van de contractuele relatie als gevolg van uw bestelling/aankoop en
                    voor direct marketing (/om u nieuwe producten of diensten aan te bieden) op
                    basis van ons gerechtvaardigd belang om te ondernemen.
                </p>
                <p>
                    Deze gegevens kunnen tevens door onze medewerkers gebruikt worden in hun
                    Outlook bestand voor zakelijke/commerci&#235;le opvolging om u bv. op de
                    hoogte te houden van opleidingen, acties, en/of andere activiteiten. .
                    Indien u niet wil dat wij uw gegevens verwerken met het oog op direct
                    marketing, volstaat het ons dat mee te delen op info@partnersatwork.be
                </p>
                <p>
                    De verstrekte persoonsgegevens worden nooit langer bijgehouden dan
                    noodzakelijk voor de gerelateerde informatie-uitwisseling en voor de uit te
                    voeren opdrachten.
                </p>
                <p>
                    De klant heeft steeds het recht om de gegeven toestemming terug in te
                    trekken
                </p>
                <p>
                    <strong><em>Rechtsgrond(en) van de verwerking </em></strong>
                </p>
                <p>
                    Persoonsgegevens worden verwerkt op basis van artikel 6.1 van de Algemene
                    Verordening Gegevensbescherming. PAND99 gebruikt je gegevens binnen het
                    wettelijk kader, op grond van je toestemming, voor de uitoefening van haar
                    taken van algemeen belang en voor de uitvoering van overeenkomsten.
                </p>
                <p>
                    <strong><em>Overmaken aan derden</em></strong>
                </p>
                <p>
                    Uw informatie wordt vertrouwelijk behandeld en niet doorgegeven aan derden
                    tenzij u hiervoor nadrukkelijk toestemming hebt verleend of om aan een door
                    de wet opgelegde verplichting te voldoen. In het kader van een politioneel
                    of gerechtelijk onderzoek kan PAND99 worden verplicht om bepaalde gegevens
                    op een vertrouwelijke manier aan de nodige overheden mee te delen.
                </p>
                <p>
                    <strong><em>Beveiliging</em></strong>
                </p>
                <p>
                    PAND99 streeft er te allen tijde naar om persoonsgegevens en privacy te
                    beschermen en draagt zorg voor passende organisatorische en technische
                    maatregelen om persoonsgegevens te beveiligen.
                </p>
                <p>
                    PAND99 maakt gebruik van een gespecialiseerd persoon die instaat voor de
                    veiligheid van het netwerk, de infrastructuur en de informatiesystemen.
                    Bovendien gebruikt wij allerlei technische maatregelen om persoonsgegevens
                    te beschermen, zoals: beveiliging met een paswoord, harddisk
                    encryptiesoftware, firewalls, antivirus.
                </p>
                <p>
                    Indien er zich een gegevenslek zou voordoen met ongunstige gevolgen voor
                    persoonsgegevens, dan wordt de klant persoonlijk verwittigd in de door de
                    wet voorziene omstandigheden.
                </p>
                <p>
                    PAND99 websites vermelden soms links naar sites van derden waarvan de
                    gebruiksvoorwaarden niet onder het toepassingsgebied van deze Privacy
                    Policy vallen. Lees aandachtig hun beleid inzake de bescherming van
                    persoonsgegevens.
                </p>
                <p>
                    <strong><em>Bewaarperiode</em></strong>
                </p>
                <p>
                    De persoonsgegevens verwerkt voor klanten- en leveranciersbeheer zullen
                    worden bewaard gedurende de termijn die noodzakelijk is om aan de
                    wettelijke vereisten te voldoen (onder andere op het gebied van
                    boekhouding) en niet langer dan nodig, conform dit privacybeleid.
                </p>
                <p>
                    <strong>
                        <em>
                            Recht van inzage, verbetering, wissing, beperking, bezwaar en
                            overdraagbaarheid van persoonsgegevens
                        </em>
                    </strong>
                </p>
                <p>
                    De klant heeft te allen tijde recht op inzage van zijn persoonsgegevens en
                    kan ze (laten) verbeteren indien ze onjuist of onvolledig zijn, ze laten
                    verwijderen, de verwerking ervan laten beperken en bezwaar te maken tegen
                    de verwerking van hem betreffende persoonsgegevens.
                </p>
                <p>
                    Bovendien, heeft de klant het recht om een kopie (in een gestructureerde,
                    gangbare en machinaal leesbare vorm) van zijn persoonsgegevens te bekomen
                    en de persoonsgegevens te laten doorsturen naar een andere vennootschap.
                </p>
                <p>
                    Teneinde bovenvermelde rechten uit te oefenen, wordt de klant gevraagd om:
                </p>
                <p>
                    - zelf de instellingen van zijn klantenaccount aan te passen; en/of
                </p>
                <p>
                    - een e-mail te verzenden naar het volgende e-mailadres:
                    info@partnersatwork.be
                </p>
                <p>
                    <strong><em>Klacht</em></strong>
                </p>
                <p>
                    De Klant beschikt over het recht om een klacht in te dienen bij de
                    Commissie voor de Bescherming van de Persoonlijke Levenssfeer
                    (Drukpersstraat 35, 1000 Brussel - commission@privacycommission.be).
                </p>
                <p>
                    <strong><em></em></strong>
                </p>
                <p>
                    <strong><em></em></strong>
                </p>
                <p>
                    <strong><em></em></strong>
                </p>
                <p>
                    <strong><em></em></strong>
                </p>
                <p>
                    <strong><em></em></strong>
                </p>
                <p>
                    <strong><em></em></strong>
                </p>
                <p>
                    <strong><em></em></strong>
                </p>
                <p>
                    <strong><em>Het gebruik van "cookies".</em></strong>
                </p>
                <p>
                    Tijdens een bezoek aan de site kunnen 'cookies' op de harde schijf van uw
                    computer geplaatst worden. Een cookie is een tekstbestand dat door de
                    server van een website in de browser van uw computer of op uw mobiel
                    apparaat geplaatst wordt wanneer u een website raadpleegt. Cookies kunnen
                    niet worden gebruikt om personen te identificeren, een cookie kan slechts
                    een machine identificeren.
                </p>
                <p>
                    'First party cookies' zijn technische cookies die gebruikt worden door de
                    bezochte site zelf en die tot doel hebben de site optimaal te laten
                    functioneren. Voorbeeld: instellingen die de gebruiker bij de vorige
                    bezoeken aan de site heeft gedaan, of nog : een vooraf ingevuld formulier
                    met data dat de gebruiker tijdens vorige bezoeken heeft gedaan.
                </p>
                <p>
                    'Third Party cookies' zijn cookies die niet afkomstig zijn van de website
                    zelf, maar wel van derden, bijvoorbeeld een aanwezige marketing of
                    advertentieplug-in. Bijvoorbeeld cookies van Facebook of Google Analytics.
                    Voor dergelijke cookies moet de bezoeker van de site eerst toestemming
                    geven - dit kan gebeuren via een balk onderaan of bovenaan de website, met
                    verwijzing naar deze policy, die het verder surfen op de website echter
                    niet verhindert.)
                </p>
                <p>
                    U kan uw internetbrowser zodanig instellen dat cookies niet worden
                    geaccepteerd, dat u een waarschuwing ontvangt wanneer een cookie
                    ge&#239;nstalleerd wordt of dat de cookies nadien van uw harde schijf
                    worden verwijderd. Dit kan u doen via de instellingen van uw browser (via
                    de help-functie). Hou er hierbij wel rekening mee dat bepaalde grafische
                    elementen niet correct kunnen verschijnen, of dat u bepaalde applicaties
                    niet zal kunnen gebruiken.
                </p>
                <p>
                    Door gebruik te maken van onze website, gaat u akkoord met ons gebruik van
                    cookies.
                </p>
                <p>
                    <strong><em>Google analytics </em></strong>
                    <em></em>
                </p>
                <p>
                    Deze website maakt gebruik van Google Analytics, een webanalyse-service die
                    wordt aangeboden door Google Inc. ("Google"). Google Analytics maakt
                    gebruik van "cookies" om de website te helpen analyseren hoe gebruikers de
                    site gebruiken. De door het cookie gegenereerde informatie over Uw gebruik
                    van de website (met inbegrip van Uw IP-adres) wordt overgebracht naar en
                    door Google opgeslagen op servers in de Verenigde Staten. Google gebruikt
                    deze informatie om bij te houden hoe u de website gebruikt, rapporten over
                    de website-activiteit op te stellen voor website-exploitanten en andere
                    diensten aan te bieden met betrekking tot website-activiteit en
                    internetgebruik. Google mag deze informatie aan derden verschaffen indien
                    Google hiertoe wettelijk wordt verplicht, of voor zover deze derden de
                    informatie namens Google verwerken. Google zal Uw IP-adres niet combineren
                    met andere gegevens waarover Google beschikt. U kunt het gebruik van
                    cookies weigeren door in Uw browser de daarvoor ge&#235;igende instellingen
                    te kiezen. Wij wijzen u er echter op dat u in dat geval wellicht niet alle
                    mogelijkheden van deze website kunt benutten. Door gebruik te maken van
                    deze website geeft u toestemming voor het verwerken van de informatie door
                    Google op de wijze en voor de doeleinden zoals hiervoor omschreven.
                </p>
                <p>
                    <strong><em></em></strong>
                </p>
                <p>
                    <strong><em>Wijzigingen</em></strong>
                </p>
                <p>
                    PAND99 behoudt zich het recht voor om deze Privacy Policy aan te passen.
                    Wijzigingen zullen steeds voorafgaand aan de inwerkingtreding ervan op deze
                    website worden gepubliceerd. Het verdient aanbeveling om deze Policy
                    geregeld te raadplegen, zodat u van deze wijzigingen op de hoogte bent.
                </p>
		    </div>
	    </section>
    </Layout>
    )
}

export default DisclaimerPage